import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { omit, isEmpty, capitalize, cloneDeep, findIndex } from 'lodash';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import Table from '../../components/tables/StackedTable';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import BranchPermissionForm from './components/BranchPermissionForm';
import {
  getBranches,
  changeBranchStatus,
  makePrincipalBranch
} from '../../services/branch';

const headers = ['Name', 'Code', 'Branch Type', 'District', 'Status'];

const filters = [
  {
    name: 'Status',
    fieldName: 'status',
    options: [
      { label: 'Active', value: 'active', defaultChecked: true },
      { label: 'Inactive', value: 'inactive' },
    ]
  }
];

export default function BranchesPage() {
  const [branches, setBranches] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [queryString, setQueryString] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilters, setStatusFilters] = useState(['active']);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showMakePrincipalModal, setShowMakePrincipalModal] = useState(false);
  const [openPermissionForm, setOpenPermissionForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    }
    _fetchData();
  }, [
    queryString,
    currentPage
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 10,
        page: currentPage,
        sortBy: 'name',
        search: queryString,
        branchType: ['principalBranch', 'branch']
      };

      if (statusFilters.length > 0) {
        params.status = statusFilters;
      }

      const data = await getBranches(params);
      setBranches(data.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString : null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  const applyFilterChanges = (event, filterIndex) => {
    const _value = event.target.value;
    const _statusFilters = cloneDeep(statusFilters);
    const fieldName = filters[filterIndex].fieldName;

    if (fieldName === 'status') {
      const valueIndex = findIndex(_statusFilters, item => item === _value);
      if (valueIndex === -1) {
        _statusFilters.push(_value);
      } else {
        _statusFilters.splice(valueIndex, 1);
      }
      setStatusFilters(_statusFilters);
    }
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeBranchStatus(selectedBranch._id);
      toast.success('Status updated successfully.');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleMakePrincipalBrnach = async (event) => {
    try {
      event.preventDefault();
      await makePrincipalBranch(selectedBranch._id);
      toast.success('Branch updated successfully.');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <CategoryListingLayout
      loading={loading}
      title="Branches"
      subTitle={`List of branches for your institution.`}
      filters={filters}
      onFilterChange={applyFilterChanges}
      onCreate={() => window.location.href = window.location.href = `/branches/create`}
    >
      {showStatusModal &&
        <ConfirmModal
          title={selectedBranch?.status === 'active' ? 'Disable branch' : 'Enable branch'}
          description={`Are you sure to ${selectedBranch?.status === 'active' ? 'disable' : 'enable'} this branch? Please double check before performing this action.`}
          actionName={selectedBranch?.status === 'active' ? 'Disable' : 'Enable'}
          onConfirm={handleStatusChange}
          onCancel={() => {
            setSelectedBranch(null);
            setShowStatusModal(false);
          }}
        />}

      {showMakePrincipalModal &&
        <ConfirmModal
          title="Make principal branch"
          description={`Are you sure to make this your principal branch? Please double check before performing this action.`}
          actionName="Confirm"
          onConfirm={handleMakePrincipalBrnach}
          onCancel={() => {
            setSelectedBranch(null);
            setShowMakePrincipalModal(false);
          }}
        />}

      {openPermissionForm &&
        <BranchPermissionForm
          branch={selectedBranch}
          onClose={async () => {
            setSelectedBranch(null);
            setOpenPermissionForm(false);
          }}
        />}

      <Table
        headers={headers}
        items={branches}
        itemsCount={branches.length}
        queryString={queryString}
        pagingData={pagingData}
        onSearch={onSearch}
        onPageChange={onPageChange}
      >
        {branches.map((branch) => (
          <tr key={branch._id} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
              {branch.name}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{branch.branchCode}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">
              {branch.branchType === 'principalBranch' ? 'Principal branch' : 'Branch'}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{branch.district}</td>
            <td
              className={`pr-3 py-4 text-sm ${branch.status === 'active' ? 'text-green-500' : 'text-red-500'}`}
            >
              {capitalize(branch.status)}
            </td>

            {branch.branchType !== 'onlineBranch' &&
              <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <ActionItems
                  branch={branch}
                  onMakePrincipal={() => {
                    setSelectedBranch(branch);
                    setShowMakePrincipalModal(true);
                  }}
                  onManagePermissions={() => {
                    setSelectedBranch(branch);
                    setOpenPermissionForm(true);
                  }}
                  onStatusChange={() => {
                    setSelectedBranch(branch);
                    setShowStatusModal(true);
                  }}
                />
              </td>}
          </tr>
        ))}
      </Table>
    </CategoryListingLayout>
  )
};

function ActionItems(props) {
  const {
    branch,
    onMakePrincipal,
    onStatusChange,
    onManagePermissions
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" href={`/branches/${branch._id.toString()}/edit`} />
      </div>
      {branch.branchType !== 'principalBranch' &&
        <div className="py-1">
          <MenuItem label="Make principal" onClick={onMakePrincipal} />
        </div>}
      <div className="py-1">
        <MenuItem label="Manage permissions" onClick={onManagePermissions} />
      </div>
      <div className="py-1">
        <MenuItem label={branch?.status === 'active' ? 'Disable' : 'Enable'} onClick={onStatusChange} />
      </div>
    </ActionMenu>
  )

};
