import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { round } from 'lodash';
import toast from 'react-hot-toast';
import { EnvelopeIcon } from '@heroicons/react/24/solid';

import StackedLayout from '../../layouts/StackedLayout';
import { SubmitButton } from '../../components/buttons';
import { getInstituteSms } from '../../services/instituteSms';
import { getSystemSmsBalance } from '../../services/sms';


const sidebarNavigation = [
  { name: 'General information', href: '/settings/institute-info' },
  { name: 'Bkash credentials', href: '/settings/bkash-credentials' },
  { name: 'SMS configuration', href: '/settings/sms/system' },
  { name: 'SMS maskings', href: '/settings/sms/maskings' },
  { name: 'SMS templates', href: '/settings/sms/templates' },
  { name: 'Branch SMS balance', href: '/settings/sms/branch/list' },
  { name: 'Analytics configuration', href: '/settings/analytics' },
];

export default function SystemSmsConfigurationPage() {
  const navigate = useNavigate();
  const [smsBalance, setSmsBalance] = useState(0);
  const [instituteSms, setInstituteSms] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const systemSmsBalance = await getSystemSmsBalance();
        setSmsBalance(systemSmsBalance?.smsBalance || 0);

        const _instituteSms = await getInstituteSms();
        setInstituteSms(_instituteSms);
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="SMS configuration"
    >
      <div>
        <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0 mb-10">
          <div className="flex items-center gap-x-6">
            <div className="flex mx-auto h-16 w-16 rounded-full items-center justify-center ring-1 ring-green-100">
              <EnvelopeIcon className="h-10 w-10 text-green-600" />
            </div>
            <h1>
              <div className="text-sm leading-6 text-gray-500">
                System SMS balance
              </div>
              <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
                BDT {round(smsBalance, 2)}
              </div>
            </h1>
          </div>
          <div className="flex items-center gap-x-4 sm:gap-x-6">
            <SubmitButton
              label="Add balance"
              onClick={() => {
                navigate(`/settings/sms/system/recharge`);
              }}
            />
          </div>
        </div>
      </div>

      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">SMS rates</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          SMS rate for non-masking and masking SMSes
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Non-masking</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{instituteSms?.smsCharges?.nonMasking}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Masking</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{instituteSms?.smsCharges?.masking}</div>
            </dd>
          </div>
        </dl>
      </div>
    </StackedLayout>
  )
};
