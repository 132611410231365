import { useState } from 'react';

import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { Input, Toggle, Select } from '../../../components/forms/fields';


const discountTypes = [
  { name: 'Flat', value: 'flat' },
  { name: 'Paercentage', value: 'percentage' }
];

export default function EbookStoreForm(props) {
  const {
    ebook,
    onClose,
    onSave
  } = props;
  const [isFree, setIsFree] = useState(ebook?.isFree || false);
  const [allowOnlinePurchase, setAllowOnlinePurchase] = useState(ebook?.allowOnlinePurchase || false);
  const [price, setPrice] = useState(ebook?.price || 0);
  const [discount, setDiscount] = useState(ebook?.discount || 0);
  const [discountType, setDiscountType] = useState(ebook.discountType || 'flat');
  const [validity, setValidty] = useState(ebook?.validity || 0);
  const [updating, setUpdating] = useState(false);

  const handleSaveEbook = async (event) => {
    try {
      setUpdating(true);
      const payload = {
        allowOnlinePurchase,
        price,
        discount,
        discountType,
        isFree,
        validity
      };
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  }

  return (
    <Sidepanel
      title="Ebook online store setup"
      subTitle="Set up required fields for online store visibility"
      disabled={updating}
      updating={updating}
      onSave={handleSaveEbook}
      onClose={onClose}
    >
      <SidepanelField
        label="Available for online store?"
      >
        <Toggle enabled={allowOnlinePurchase} onChange={(event, value) => setAllowOnlinePurchase(value)} />
      </SidepanelField>

      {allowOnlinePurchase &&
        <>
          <SidepanelField
            label="Is Fee?"
          >
            <Toggle enabled={isFree} onChange={(value) => setIsFree(value)} />
          </SidepanelField>

          <SidepanelField
            label="Ebook price (BDT)"
          >
            <Input type="number" name="price" value={price} onChange={(event) => setPrice(event.target.value)} />
          </SidepanelField>

          <SidepanelField
            label="Discount value"
          >
            <Input type="number" name="discount" value={discount} onChange={(event) => setDiscount(event.target.value)} />
          </SidepanelField>

          <SidepanelField
            label="Discount type"
          >
            <Select name="discountType" value={discountType} options={discountTypes} onChange={(event) => setDiscountType(event.target.value)} />
          </SidepanelField>

          <SidepanelField
            label="Ebook validity (years)"
          >
            <Input type="number" name="validity" value={validity} onChange={(event) => setValidty(event.target.value)} />
          </SidepanelField>
        </>}
    </Sidepanel>
  )
};
