import http from '../helpers/http';


export const createCourse = async (payload) => {
  try {
    const { data } = await http.post(`/api/courses`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCourses = async (params) => {
  try {
    const config = { params };
    const { data } = await http.get(`/api/courses`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCourse = async (id) => {
  try {
    const { data } = await http.get(`/api/courses/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateCourse = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/courses/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeCourseStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/courses/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const completeCourse = async (id) => {
  try {
    const { data } = await http.put(`/api/courses/${id}/complete`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const realizeDues = async (id) => {
  try {
    const { data } = await http.put(`/api/courses/${id}/realize-dues`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCoursesForBranch = async (branchId, params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/courses/branch/${branchId}`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
