import { useState, useEffect } from 'react';
import { cloneDeep, forEach, isEmpty } from 'lodash';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import { SubmitButton, Button } from '../../components/buttons';
import { Select } from '../../components/forms/fields';
import { getSmsTemplate, createSmsTemplate, updateSmsTemplate, getInstituteSmsMaskingList } from '../../services/instituteSms';


const sidebarNavigation = [
  { name: 'General information', href: '/settings/institute-info' },
  { name: 'Bkash credentials', href: '/settings/bkash-credentials' },
  { name: 'SMS configuration', href: '/settings/sms/system' },
  { name: 'SMS maskings', href: '/settings/sms/maskings' },
  { name: 'SMS templates', href: '/settings/sms/templates' },
  { name: 'Branch SMS balance', href: '/settings/sms/branch/list' },
  { name: 'Analytics configuration', href: '/settings/analytics' },
];

const defaultUpdateStates = {
  otpMasking: false,
  otpTemplate: false,
  registrationMasking: false,
  registrationTemplate: false,
  paymentMasking: false,
  paymentTemplate: false,
  admissionMasking: false,
  admissionTemplate: false,
  resultMasking: false,
  resultTemplate: false,
};

export default function SmsTemplatePage() {
  const [otpSmsTemplate, setOtpSmsTemplate] = useState(null);
  const [otpSenderId, setOtpSenderId] = useState(null);
  const [otpMaskingId, setOtpMaskingId] = useState(null);
  const [otpTemplate, setOtpTemplate] = useState('');
  const [registrationSmsTemplate, setRegistrationSmsTemplate] = useState(null);
  const [registrationSenderId, setRegistrationSenderId] = useState(null);
  const [registrationMaskingId, setRegistrationMaskingId] = useState(null);
  const [registrationTemplate, setRegistrationTemplate] = useState('');
  const [paymentSmsTemplate, setPaymentSmsTemplate] = useState(null);
  const [paymentSenderId, setPaymentSenderId] = useState(null);
  const [paymentMaskingId, setPaymentMaskingId] = useState(null);
  const [paymentTemplate, setPaymentTemplate] = useState('');
  const [admissionSmsTemplate, setAdmissionSmsTemplate] = useState(null);
  const [admissionSenderId, setAdmissionSenderId] = useState(null);
  const [admissionMaskingId, setAdmissionMaskingId] = useState(null);
  const [admissionTemplate, setAdmissionTemplate] = useState('');
  const [resultSmsTemplate, setResultSmsTemplate] = useState(null);
  const [resultSenderId, setResultSenderId] = useState(null);
  const [resultMaskingId, setResultMaskingId] = useState(null);
  const [resultTemplate, setResultTemplate] = useState('');
  const [maskingOptions, setMaskingOptions] = useState([]);
  const [updateStates, setUpdateState] = useState(defaultUpdateStates);

  useEffect(() => {
    async function fetchData() {
      try {
        const _maskingList = await getInstituteSmsMaskingList();
        let _maskingOptions = [];
        forEach(_maskingList, (masking) => {
          _maskingOptions.push({
            name: masking.senderId,
            value: masking._id,
          })
        });
        setMaskingOptions(_maskingOptions);
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchSmsTemplates();
    };
    _fetchData();
  }, []);

  const fetchSmsTemplates = async () => {
    try {
      const _otpTemplate = await getSmsTemplate('otp');
      setOtpSmsTemplate(_otpTemplate);
      setOtpMaskingId(_otpTemplate?.smsMasking?._id);
      setOtpSenderId(_otpTemplate?.smsMasking?.senderId);
      setOtpTemplate(_otpTemplate.template);

      const _registrationTemplate = await getSmsTemplate('registration');
      setRegistrationSmsTemplate(_registrationTemplate);
      setRegistrationMaskingId(_registrationTemplate?.smsMasking?._id);
      setRegistrationSenderId(_registrationTemplate?.smsMasking?.senderId);
      setRegistrationTemplate(_registrationTemplate.template);

      const _paymentTemplate = await getSmsTemplate('payment');
      setPaymentSmsTemplate(_paymentTemplate);
      setPaymentMaskingId(_paymentTemplate?.smsMasking?._id);
      setPaymentSenderId(_paymentTemplate?.smsMasking?.senderId);
      setPaymentTemplate(_paymentTemplate.template);

      const _admissionTemplate = await getSmsTemplate('admission');
      setAdmissionSmsTemplate(_admissionTemplate);
      setAdmissionMaskingId(_admissionTemplate?.smsMasking?._id);
      setAdmissionSenderId(_admissionTemplate?.smsMasking?.senderId);
      setAdmissionTemplate(_admissionTemplate.template);

      const _resultTemplate = await getSmsTemplate('result');
      setResultSmsTemplate(_resultTemplate);
      setResultMaskingId(_resultTemplate?.smsMasking?._id);
      setResultSenderId(_resultTemplate?.smsMasking?.senderId);
      setResultTemplate(_resultTemplate.template);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onEdit = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = true;
    setUpdateState(_updateState);
  };

  const onCancel = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = false;
    setUpdateState(_updateState);
    if (fieldName === 'otpMasking') {
      setOtpSenderId(otpSmsTemplate?.smsMasking?.senderId);
      setOtpMaskingId(otpSmsTemplate?.smsMasking?._id);
    }
    else if (fieldName === 'otpTemplate') { setOtpTemplate(otpSmsTemplate?.tempalate); }
    else if (fieldName === 'registrationMasking') {
      setRegistrationSenderId(registrationSmsTemplate?.smsMasking?.senderId);
      setRegistrationMaskingId(registrationSmsTemplate?.smsMasking?._id);
    } else if (fieldName === 'paymentMasking') {
      setPaymentSenderId(paymentSmsTemplate?.smsMasking?.senderId);
      setPaymentMaskingId(paymentSmsTemplate?.smsMasking?._id);
    } else if (fieldName === 'admissionMasking') {
      setAdmissionSenderId(admissionSmsTemplate?.smsMasking?.senderId);
      setAdmissionMaskingId(admissionSmsTemplate?.smsMasking?._id);
    } else if (fieldName === 'resultMasking') {
      setResultSenderId(resultSmsTemplate?.smsMasking?.senderId);
      setResultMaskingId(resultSmsTemplate?.smsMasking?._id);
    }
  };

  const saveOtpTemplate = async (event, fieldName) => {
    try {
      event.preventDefault();
      let payload = {};
      if (fieldName === 'otpMasking') {
        payload = {
          smsMasking: isEmpty(otpMaskingId) ? null : otpMaskingId
        }
      }

      if (otpSmsTemplate) {
        await updateSmsTemplate(otpSmsTemplate._id, payload);
      } else {
        payload.eventType = 'otp'
        await createSmsTemplate(payload)
      }
      toast.success('Updated successfully');
      await fetchSmsTemplates();
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveRegistrationTemplate = async (event, fieldName) => {
    try {
      event.preventDefault();
      let payload = {};
      if (fieldName === 'registrationMasking') {
        payload = {
          smsMasking: isEmpty(registrationMaskingId) ? null : registrationMaskingId
        }
      }

      if (registrationSmsTemplate) {
        await updateSmsTemplate(registrationSmsTemplate._id, payload);
      } else {
        payload.eventType = 'registration'
        await createSmsTemplate(payload)
      }
      toast.success('Updated successfully');
      await fetchSmsTemplates();
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const savePaymentTemplate = async (event, fieldName) => {
    try {
      event.preventDefault();
      let payload = {};
      if (fieldName === 'paymentMasking') {
        payload = {
          smsMasking: isEmpty(paymentMaskingId) ? null : paymentMaskingId
        }
      }

      if (paymentSmsTemplate) {
        await updateSmsTemplate(paymentSmsTemplate._id, payload);
      } else {
        payload.eventType = 'payment'
        await createSmsTemplate(payload)
      }
      toast.success('Updated successfully');
      await fetchSmsTemplates();
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveAdmissionTemplate = async (event, fieldName) => {
    try {
      event.preventDefault();
      let payload = {};
      if (fieldName === 'admissionMasking') {
        payload = {
          smsMasking: isEmpty(admissionMaskingId) ? null : admissionMaskingId
        }
      }

      if (admissionSmsTemplate) {
        await updateSmsTemplate(admissionSmsTemplate._id, payload);
      } else {
        payload.eventType = 'admission'
        await createSmsTemplate(payload)
      }
      toast.success('Updated successfully');
      await fetchSmsTemplates();
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveResultTemplate = async (event, fieldName) => {
    try {
      event.preventDefault();
      let payload = {};
      if (fieldName === 'resultMasking') {
        payload = {
          smsMasking: isEmpty(resultMaskingId) ? null : resultMaskingId
        }
      }

      if (resultSmsTemplate) {
        await updateSmsTemplate(resultSmsTemplate._id, payload);
      } else {
        payload.eventType = 'result'
        await createSmsTemplate(payload)
      }
      toast.success('Updated successfully');
      await fetchSmsTemplates();
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="SMS templates"
    >
      <div className="mb-10">
        {/* <h2 className="text-base font-semibold leading-7 text-gray-900">OTP</h2> */}
        {/* <p className="mt-1 text-sm leading-6 text-gray-500">
          Set masking options, template for OTP SMS
        </p> */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">OTP masking</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.otpMasking && <div className="text-gray-900">{otpSenderId || 'No Masking'}</div>}
              {updateStates.otpMasking &&
                <div className="w-full">
                  <Select emptyStateLabel="No Masking" value={otpMaskingId} options={maskingOptions} onChange={(event) => setOtpMaskingId(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => saveOtpTemplate(event, 'otpMasking')} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'otpMasking')} />
                  </div>
                </div>}

              {!updateStates.otpMasking &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'otpMasking')}
                >
                  Update
                </button>}
            </dd>
          </div>
        </dl>
      </div>

      <div className="mb-10">
        {/* <h2 className="text-base font-semibold leading-7 text-gray-900">New user registration</h2> */}
        {/* <p className="mt-1 text-sm leading-6 text-gray-500">
          Set masking options, template for new user registration SMS
        </p> */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">New user masking</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.registrationMasking && <div className="text-gray-900">{registrationSenderId || 'No Masking'}</div>}
              {updateStates.registrationMasking &&
                <div className="w-full">
                  <Select emptyStateLabel="No Masking" value={registrationMaskingId} options={maskingOptions} onChange={(event) => setRegistrationMaskingId(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => saveRegistrationTemplate(event, 'registrationMasking')} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'registrationMasking')} />
                  </div>
                </div>}

              {!updateStates.registrationMasking &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'registrationMasking')}
                >
                  Update
                </button>}
            </dd>
          </div>
        </dl>
      </div>

      <div className="mb-10">
        {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Result SMS</h2> */}
        {/* <p className="mt-1 text-sm leading-6 text-gray-500">
          Set masking options, template for new course enrollment SMS
        </p> */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Result sms masking</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.resultMasking && <div className="text-gray-900">{resultSenderId || 'No Masking'}</div>}
              {updateStates.resultMasking &&
                <div className="w-full">
                  <Select emptyStateLabel="No Masking" value={resultMaskingId} options={maskingOptions} onChange={(event) => setResultMaskingId(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => saveResultTemplate(event, 'resultMasking')} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'resultMasking')} />
                  </div>
                </div>}

              {!updateStates.resultMasking &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'resultMasking')}
                >
                  Update
                </button>}
            </dd>
          </div>
        </dl>
      </div>

      <div className="mb-10">
        {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Payment SMS</h2> */}
        {/* <p className="mt-1 text-sm leading-6 text-gray-500">
          Set masking options, template for payment SMS
        </p> */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Payment sms masking</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.paymentMasking && <div className="text-gray-900">{paymentSenderId || 'No Masking'}</div>}
              {updateStates.paymentMasking &&
                <div className="w-full">
                  <Select emptyStateLabel="No Masking" value={paymentMaskingId} options={maskingOptions} onChange={(event) => setPaymentMaskingId(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => savePaymentTemplate(event, 'paymentMasking')} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'paymentMasking')} />
                  </div>
                </div>}

              {!updateStates.paymentMasking &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'paymentMasking')}
                >
                  Update
                </button>}
            </dd>
          </div>
        </dl>
      </div>

      <div className="mb-10">
        {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Enrollment SMS</h2> */}
        {/* <p className="mt-1 text-sm leading-6 text-gray-500">
          Set masking options, template for new course enrollment SMS
        </p> */}
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Admission sms masking</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.admissionMasking && <div className="text-gray-900">{admissionSenderId || 'No Masking'}</div>}
              {updateStates.admissionMasking &&
                <div className="w-full">
                  <Select emptyStateLabel="No Masking" value={admissionMaskingId} options={maskingOptions} onChange={(event) => setAdmissionMaskingId(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => saveAdmissionTemplate(event, 'admissionMasking')} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'admissionMasking')} />
                  </div>
                </div>}

              {!updateStates.admissionMasking &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'admissionMasking')}
                >
                  Update
                </button>}
            </dd>
          </div>
        </dl>
      </div>

    </StackedLayout>
  )
};
