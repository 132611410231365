import http from '../helpers/http';


export const sendNumberSms = async (payload) => {
  try {
    const { data } = await http.post(`/api/sms-events/number-sms`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const sendExcelSms = async (payload) => {
  try {
    const { data } = await http.post(`/api/sms-events/excel-sms`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getSmsEvents = async (params) => {
  try {
    const config = { params: params || {} };
    const { data } = await http.get(`/api/sms-events`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
