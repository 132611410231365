import { Input, Toggle } from '../../../components/forms/fields';


export default function SubjectForm(props) {
  const subjectCount = props.subjectCount;
  const subject = props.subject;
  const index = props.index;
  const onFieldChange = props.onFieldChange;
  const onToggleFieldChange = props.onToggleFieldChange;
  const onDelete = props.onDelete;

  console.log(subject);

  return (
    <>
      <Input col={2} label="Subject" name="subject" value={subject.name}
        onChange={(event) => onFieldChange(event, index)}
      />

      <Input type="number" col={1} label="Questions" name="noOfQuestions" value={subject.noOfQuestions}
        onChange={(event) => onFieldChange(event, index)}
      />

      <Toggle col={1} label="Mandatory" name="isMandatory" enabled={subject.isMandatory} onChange={(value) => onToggleFieldChange('isMandatory', index, value)} />

      {subjectCount > 1 &&
        <div className="mt-9 col-span-1">
          <a
            href="#"
            className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
            onClick={(event) => onDelete(event, index)}
          >
            Remove
          </a>
        </div>}
    </>
  )
}
