import { useEffect, useState } from 'react';

import { InlineCheckbox } from '../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { getBranches } from '../../../services/branch';
import toast from 'react-hot-toast';
import { cloneDeep, findIndex } from 'lodash';



export default function MaskingBranchPermissionForm(props) {
  const {
    masking,
    onSave,
    onClose
  } = props;
  const [updating, setUpdating] = useState(false);
  const [branches, setBranches] = useState([]);
  const [allowedBranches, setAllowedBranches] = useState([]);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _branches = await getBranches({ status: 'active', branchType: ['principalBranch', 'branch'] });
        setBranches(_branches);

        setAllowedBranches(masking.branches);
      } catch (error) {
        toast.error(error.mes)
      }
    }
    _fetchData();
  }, [])

  const handleSavePermission = async (event) => {
    try {
      setUpdating(true);
      const payload = {
        branches: allowedBranches
      };
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const handleSelectBranch = async (branchId) => {
    let _allowedbranches = cloneDeep(allowedBranches);
    const branchIndex = findIndex(allowedBranches, b => b.toString() === branchId.toString());

    if (branchIndex === -1) {
      _allowedbranches.push(branchId);
      setAllowedBranches(_allowedbranches);
    } else {
      _allowedbranches.splice(branchIndex, 1);
      setAllowedBranches(_allowedbranches);
    }
  };

  const checkBranchAllowed = (branchId) => {
    const branchIndex = findIndex(allowedBranches, b => b.toString() === branchId.toString());
    return branchIndex !== -1;
  };

  const isDisabled = () => {
    return updating;
  };

  return (
    <Sidepanel
      title={`${masking?.name}: Branch permissions`}
      subTitle={`Set permissions to the branches`}
      onSave={handleSavePermission}
      onClose={onClose}
      updating={updating}
      disabled={isDisabled()}
    >
      <SidepanelField
        label="Branches"
      >
        {branches?.map((branch) => (
          <InlineCheckbox
            key={branch._id}
            label={branch.name}
            checked={checkBranchAllowed(branch._id)}
            onChange={() => handleSelectBranch(branch._id)}
          />
        ))}
      </SidepanelField>
    </Sidepanel>
  );
};
