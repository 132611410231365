import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';

import StackedLayout from './StackedLayout';
import { SubmitButton } from '../components/buttons';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function CategoryListingLayout(props) {
  const {
    loading,
    navigation,
    navCurrent,
    onCreate,
    title,
    subTitle,
    filters,
    onFilterChange,
    hideHeader,
    warningMessage,
    breadcrumbPages,
    createButtonText,
    hideCreateNewButton
  } = props;
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const hideFilters = props.hideFilters || !filters || filters?.length <= 0;

  return (
    <StackedLayout
      loading={loading}
      navigation={navigation}
      navCurrent={navCurrent}
      warningMessage={warningMessage}
      breadcrumbPages={breadcrumbPages}
    >
      <div>
        {/* Mobile filter dialog */}
        {!hideFilters &&
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    <form className="mt-4">
                      {filters.map((section, sectionIdx) => {
                        // if (!showFilter(section.fieldName)) { return; }
                        return (
                          <Disclosure as="div" key={section.name} className="border-t border-gray-200 pb-4 pt-4">
                            {({ open }) => (
                              <fieldset>
                                <legend className="w-full px-2">
                                  <Disclosure.Button className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                                    <span className="text-sm font-medium text-gray-900">{section.name}</span>
                                    <span className="ml-6 flex h-7 items-center">
                                      <ChevronDownIcon
                                        className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Disclosure.Button>
                                </legend>
                                <Disclosure.Panel className="px-4 pb-2 pt-4">
                                  <div className="space-y-6">
                                    {section.options.map((option, optionIdx) => (
                                      <div key={option.value} className="flex items-center">
                                        <input
                                          id={`${section.fieldName}-${optionIdx}-mobile`}
                                          name={`${section.fieldName}`}
                                          value={option.value}
                                          type="checkbox"
                                          defaultChecked={option.defaultChecked}
                                          className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                                          onChange={(event) => onFilterChange(event, sectionIdx)}
                                        />
                                        <label
                                          htmlFor={`${section.fieldName}-${optionIdx}-mobile`}
                                          className="ml-3 text-sm text-gray-500"
                                        >
                                          {option.label}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </fieldset>
                            )}
                          </Disclosure>
                        )
                      })}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>}

        <div>
          {!hideHeader &&
            <div className="flex justify-between border-b border-gray-200 pb-6">
              <div>
                <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
                <p className="mt-2 text-base text-gray-500">
                  {subTitle}
                </p>
              </div>

              {!hideCreateNewButton && onCreate &&
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <SubmitButton label={createButtonText || 'Create new'} onClick={onCreate} />
                </div>}
            </div>}

          <div className="pt-6 lg:grid lg:grid-cols-4 lg:gap-x-8 xl:grid-cols-5">
            {!hideFilters &&
              <aside>
                <h2 className="sr-only">Filters</h2>

                <button
                  type="button"
                  className="inline-flex items-center lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="text-sm font-medium text-gray-700">Filters</span>
                  <PlusIcon className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                </button>

                <div className="hidden lg:block">
                  <form className="space-y-4 divide-y divide-gray-200">
                    {filters.map((section, sectionIdx) => {
                      // if (!showFilter(section.fieldName)) { return; }
                      return (
                        <div key={section.name} className={sectionIdx === 0 ? null : 'pt-4'}>
                          <fieldset>
                            <legend className="block text-sm font-medium text-gray-900">{section.name}</legend>
                            <div className="space-y-3 pt-6">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} className="flex items-center">
                                  <input
                                    id={`${section.fieldName}-${optionIdx}`}
                                    name={`${section.fieldName}`}
                                    value={option.value}
                                    type="checkbox"
                                    defaultChecked={option.defaultChecked}
                                    className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                                    onChange={(event) => onFilterChange(event, sectionIdx)}
                                  />
                                  <label htmlFor={`${section.fieldName}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                        </div>
                      )
                    })}
                  </form>
                </div>
              </aside>}

            <div className={`mt-6 lg:mt-0 ${hideFilters ? 'lg:col-span-full xl:col-span-full' : 'lg:col-span-3 xl:col-span-4'}`}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};