import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { isEmpty, omit, capitalize, includes } from 'lodash';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import Table from '../../components/tables/StackedTable';
import SectionHeader from '../../components/SectionHeader';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import { ButtonWithIcon } from '../../components/buttons';
import DownloadInvoiceModal from '../../components/modals/DownloadInvoiceModal';
import { getInvoices, getInvoicePdf, createInvoicePdf } from '../../services/invoice';
import { getNormalizedDateTime } from '../../utils';


const sidebarNavigation = [
  { name: 'Collect payment', href: '/student-payments/collect' },
  { name: 'All due payments', href: '/student-payments/due' },
  { name: 'All paid payments', href: '/student-payments/paid' },
  { name: 'Other branch payments', href: '/student-payments/central' },
];
const headers = ['Reg. no', 'Student Name', 'Program', 'Payment for', 'Branch', 'Amount', 'Status', 'Due Date'];
const paidHeaders = ['Reg. no', 'Student Name', 'Program', 'Payment for', 'Branch', 'Amount', 'Status', 'Payment Date'];

export default function StudentInvoicesPage(props) {
  const status = props.status;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState({});
  const [queryString, setQueryString] = useState(null);
  const [statusFilters, setStatusFilters] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        await fetchInvoices();
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, [
    currentPage,
    queryString,
    statusFilters
  ]);

  const fetchInvoices = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        studentId: queryString,
      };

      if (status === 'due') {
        params.sortBy = 'dueDate';
        params.status = ['due', 'overdue'];
      } else {
        params.status = 'paid';
        params.sortBy = 'paymentDate';
        params.sortOrder = 'desc';
      }

      const data = await getInvoices(params);
      setInvoices(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString : null;
    setQueryString(q);
    setCurrentPage(1);
    setStatusFilters([]);
    setLoading(true);
  };

  const handlePrintInvoice = async (invoiceId) => {
    try {
      const pdfUrl = await getInvoicePdf(invoiceId);

      if (pdfUrl?.publicUrl) {
        window.open(pdfUrl?.publicUrl);
      } else {
        toast.error('The invoice is being ready, please wait.')
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCreateInvoicePdf = async (invoiceId) => {
    try {
      await createInvoicePdf(invoiceId);
      toast.success('Your request is being processed.');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={status === 'due' ? 'All due payments' : 'All paid payments'}
    >
      {showDownloadModal &&
        <DownloadInvoiceModal
          currentUser={currentUser}
          status={status}
          onCancel={() => setShowDownloadModal(false)}
        />}

      <SectionHeader
        title={status === 'due' ? 'Due invoices' : 'Paid invoices'}
        subTitle={`List of all ${status} invoices of your institution.`}
      >
        <div>
          <div className="flex space-x-4">
            <ButtonWithIcon label="Download" Icon={ArrowDownTrayIcon} onClick={() => setShowDownloadModal(true)} />
          </div>
        </div>
      </SectionHeader>
      <Table
        headers={status === 'due' ? headers : paidHeaders}
        itemsCount={invoices.length}
        pagingData={pagingData}
        queryString={queryString}
        onPageChange={onPageChange}
        onSearch={onSearch}
        searchPlaceholder="Search by student registration no"
      >
        {invoices?.map((invoice) => (
          <tr key={invoice._id} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
              {invoice?.user?.registrationNo}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.user?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.program?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.title || invoice?.course?.name || `${invoice.courses.length === 1 ? `${invoice.courses[0].name}` : `${invoice.courses.length} courses`}`}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.branch?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">
              {invoice.status === 'paid' ? invoice.paidAmount : invoice.amount}
            </td>
            <td
              className={`pr-3 py-4 text-sm ${invoice.status === 'overdue' ? 'text-red-500' : 'text-gray-500'}`}
            >
              {capitalize(invoice?.status)}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">
              {getNormalizedDateTime(invoice?.paymentDate || invoice?.dueDate, 'DD MMM YYYY')}
            </td>
            {invoice.status === 'paid' &&
              <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <ActionItems
                  currentUser={currentUser}
                  onPrint={() => handlePrintInvoice(invoice._id)}
                  onCreatePdf={() => handleCreateInvoicePdf(invoice._id)}
                />
              </td>}
            {/* <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              {invoice.status === 'paid' &&
              <div className="flex justify-end items-center space-x-2">
                <p
                  className="text-indigo-600 hover:text-indigo-500 underline cursor-pointer"
                  onClick={() => handlePrintInvoice(invoice._id)}
                >
                  Print
                </p>
              </div>}
            </td> */}
          </tr>
        ))}

      </Table>

    </StackedLayout>
  )
};

function ActionItems(props) {
  const {
    currentUser,
    onPrint,
    onCreatePdf
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Print invoice" onClick={onPrint} />
      </div>

      {includes(['owner', 'admin', 'branchAdmin'], currentUser.role) &&
        <div className="py-1">
          <MenuItem label="Re-generate invoice pdf" onClick={onCreatePdf} />
        </div>}
    </ActionMenu>
  )

};
