import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import { getInstituteSmsMaskingList, updateInstituteSmsMasking } from '../../services/instituteSms';
import Table from '../../components/tables/StackedTable';
import MaskingBranchPermissionForm from './components/MaskingBranchPermissionForm';


const sidebarNavigation = [
  { name: 'General information', href: '/settings/institute-info' },
  { name: 'Bkash credentials', href: '/settings/bkash-credentials' },
  { name: 'SMS configuration', href: '/settings/sms/system' },
  { name: 'SMS maskings', href: '/settings/sms/maskings' },
  { name: 'SMS templates', href: '/settings/sms/templates' },
  { name: 'Branch SMS balance', href: '/settings/sms/branch/list' },
  { name: 'Analytics configuration', href: '/settings/analytics' },
];

export default function SmsMaskingPage() {
  const [maskingList, setMaskingList] = useState([]);
  const [selectedMasking, setSelectedMasking] = useState(null);
  const [openPermissionForm, setOpenPermissionForm] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetchMakingList();
    };
    fetchData();
  }, []);

  const fetchMakingList = async () => {
    try {
      const _maskingList = await getInstituteSmsMaskingList();
      setMaskingList(_maskingList);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const saveBranchPermission = async (event, payload) => {
    try {
      event.preventDefault();
      await updateInstituteSmsMasking(selectedMasking._id, payload);
      toast.success('Permission updated successfully');
      await fetchMakingList();
      setOpenPermissionForm(false);
      setSelectedMasking(null);
    } catch (error) {
      toast.error(error.meesage);
    }
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="SMS maskings"
    >
      {openPermissionForm &&
        <MaskingBranchPermissionForm
          masking={selectedMasking}
          onSave={saveBranchPermission}
          onClose={() => {
            setSelectedMasking(null);
            setOpenPermissionForm(false);
          }}
        />}
      <div>
        <Table
          title="Masking list"
          headers={['Masking', 'Allowed branches']}
          items={maskingList}
          itemsCount={maskingList.length}
        >
          {maskingList?.map(masking => (
            <tr key={masking._id} className="text-center">
              <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                {masking.senderId}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">{masking?.branches?.length || 0}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                <p
                  className="text-indigo-600 underline cursor-pointer"
                  onClick={() => {
                    setSelectedMasking(masking);
                    setOpenPermissionForm(true);
                  }}
                >Branch permissions</p>
              </td>
            </tr>
          ))}

        </Table>
      </div>
    </StackedLayout>
  )
};
