import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { includes, orderBy, cloneDeep, isEmpty, capitalize, findIndex, forEach } from 'lodash';
import { format, startOfMonth, parseISO, startOfToday } from 'date-fns'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { Input, Select, InlineCheckbox } from '../../components/forms/fields';
import { SubmitButton, Button } from '../../components/buttons';
import { getStudentByRegistrationNumber } from '../../services/student';
import { getCoursesForBranch } from '../../services/course';
import { getBatches } from '../../services/batch';
import { getPrograms } from '../../services/program';
import { getBranches } from '../../services/branch';
import { getAdmittedProgramForProgram } from '../../services/programAdmission';
import { getEnrolledCourses } from '../../services/enrolledCourse';
import {
  enrollFreeCourse,
  enrollCourseByAdmin
} from '../../services/enrolledCourse';
import { getDiscount, getVat } from '../../utils';


const sidebarNavigation = [
  { name: 'Student list', href: '/students' },
  { name: 'Student admission', href: '/students/admission' },
  { name: 'Bulk admission', href: '/students/bulk-admission' }
];

export default function StudentAdmissionPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [registrationNo, setRegistrationNo] = useState(location?.state?.registrationNo || '');
  const [student, setStudent] = useState(null);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [batch, setBatch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState(null);
  const [startMonth, setStartMonth] = useState(null);
  const [totalCourseFee, setTotalCourseFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [specialDiscount, setSpecialDiscount] = useState(0);
  const [monthlyScholarship, setMonthlyScholarship] = useState(0);
  const [vat, setVat] = useState(0);
  const [includeVat, setIncludeVat] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [reference, setReference] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [discountOnAdmissionFee, setDiscountOnAdmissionFee] = useState(0);
  const [programEnrolled, setProgramEnrolled] = useState(false);
  const [existingEnrolledCount, setExistingEnrolledCount] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useState(() => {
    async function _fetchData() {
      try {
        if (location?.state?.registrationNo) {
          const _student = await getStudentByRegistrationNumber(registrationNo);
          setStudent(_student);
        }

        if (!currentBranch?.permissions?.canEnrollOnlineCourses) {
          setCourseType('offline');
        }

        if ((includes(['owner', 'admin'], currentUser?.role) || currentBranch?.permissions?.canAdmitCentralStudents)) {
          const _branches = await getBranches({ status: 'active' });
          setBranches(orderBy(_branches, 'name'));
        } else {
          setBranch(currentBranch);
        }

        const _programs = await getPrograms({ status: 'active' });
        setPrograms(orderBy(_programs, 'name'));
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function fetchCourses() {
      try {
        if (program && branch) {
          const programAdmission = await getAdmittedProgramForProgram(program._id, { studentId: student._id });
          setProgramEnrolled(programAdmission ? true : false);

          let params = {
            program: program._id,
            status: 'active',
            includeOnlineCourses: currentBranch?.permissions?.canEnrollOnlineCourses,
            studentId: student._id
          };

          if (program.paymentCircle === 'onetime') {
            params.excludeEnrolledCourses = true;
          }

          const branchId = branch?._id;
          const _courses = await getCoursesForBranch(branchId, params);
          setCourses(orderBy(_courses, 'name'));

          if (program.paymentCircle === 'month' && programAdmission) {
            setStartMonth(format(startOfMonth(startOfToday()), 'yyyy-MM'));
            setMonthlyScholarship(programAdmission.monthlyScholarship || 0);
            const params = {
              user: student._id,
              program: program._id,
              status: 'active'
            };
            const enrolledCourses = await getEnrolledCourses(params);
            setExistingEnrolledCount(enrolledCourses.length);
            const _selectedCourses = [];
            forEach(enrolledCourses, ec => _selectedCourses.push({ course: ec.course, isEnrolled: true, isFree: ec.isFree, batches: [] }));
            setSelectedCourses(_selectedCourses);
          }
        } else {
          setCourses([]);
          setCourse(null);
          setBatch(null);
          setBranch(null);
          setIncludeVat(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchCourses();
    setSelectedCourses([]);
  }, [program, branch]);

  useEffect(() => {
    let _totalCourseFee = 0;
    let _discount = 0;
    let _specialDiscount = 0;
    forEach(selectedCourses, selectedCourse => {
      if (!selectedCourse.isFree) {
        const course = selectedCourse.course;
        _totalCourseFee = _totalCourseFee + course.fee;
        const courseDiscount = getDiscount(course);
        _discount = _discount + courseDiscount;
        _specialDiscount = _specialDiscount + (selectedCourse.specialDiscount || 0);
      }
    });
    setTotalCourseFee(_totalCourseFee);
    setDiscount(_discount);
    setSpecialDiscount(_specialDiscount);
    // setMonthlyScholarship(0);
  }, [selectedCourses])

  useEffect(() => {
    const courseFee = totalCourseFee || 0;
    let amount = courseFee - discount - (specialDiscount || 0);
    if (program?.paymentCircle === 'month') {
      amount = courseFee - discount - (monthlyScholarship || 0);
    }
    const _vat = getVat(amount, includeVat);
    setVat(_vat);
  }, [totalCourseFee, includeVat, discount, specialDiscount, monthlyScholarship]);

  useEffect(() => {

    let _totalAmount = totalCourseFee + vat - discount - (specialDiscount || 0);
    if (program?.paymentCircle === 'month') {
      _totalAmount = totalCourseFee + vat - discount - (monthlyScholarship || 0);
    }
    setTotalAmount(_totalAmount);
  }, [totalCourseFee, vat, discount, specialDiscount, monthlyScholarship]);

  const handleEnrollCourses = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        isAdmin: true,
        studentId: student._id,
        specialDiscount: parseFloat(specialDiscount),
        monthlyScholarship: parseFloat(monthlyScholarship),
        reference: reference,
        currentBranch: currentBranch._id,
        programId: program._id,
        totalCourseFee: totalCourseFee,
        discount: discount,
        includeVat: includeVat,
        branch: branch?._id || currentBranch?._id,
        startMonth: startMonth,
        totalAmount: totalAmount
      };

      let _courses = [];
      forEach(selectedCourses, _course => {
        _courses.push({
          courseId: _course.course._id,
          batchId: _course.selectedBatch,
          specialDiscount: _course.specialDiscount,
          isEnrolled: _course.isEnrolled,
          printedBooksIncluded: _course.printedBooksIncluded
        })
      });
      payload.courses = _courses;

      if (!programEnrolled && program.admissionFeeEnabled) {
        payload.discountOnAdmissionFee = parseFloat(discountOnAdmissionFee || 0);
      }

      const results = await enrollCourseByAdmin(payload);
      navigate('/students/admission/success', {
        state: { enrolledCourses: results }
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const searchStudent = async (event) => {
    try {
      event.preventDefault();
      const _student = await getStudentByRegistrationNumber(registrationNo);
      setStudent(_student);
    } catch (error) {
      setStudent(null);
      toast.error(error.message);
    }
  };

  const handleSelectCourse = async (course) => {
    let _selectedCourses = cloneDeep(selectedCourses);
    const courseIndex = findIndex(_selectedCourses, sc => sc.course._id.toString() === course._id.toString());
    if (courseIndex === -1) {
      let selectedCourse = { course: course };

      if (includes(['offline', 'live'], course.courseType)) {
        let params = { course: course._id, status: 'active' };
        if (course.courseType === 'offline' && branch) {
          params.branch = branch._id;
        }

        try {
          const _batches = await getBatches(params);
          selectedCourse.batches = _batches;
          selectedCourse.selectedBatch = null;
        } catch (error) {
          toast.error(error.message);
        }
      }
      selectedCourse.printedBooksIncluded = course.printedBooksIncluded;
      _selectedCourses.push(selectedCourse);
      setSelectedCourses(_selectedCourses);
    } else {
      _selectedCourses.splice(courseIndex, 1);
      setSelectedCourses(_selectedCourses);
    }
  };

  const handleSelectBatch = (courseId, batchId) => {
    let _selectedCourses = cloneDeep(selectedCourses);
    const courseIndex = findIndex(_selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    _selectedCourses[courseIndex].selectedBatch = batchId;
    setSelectedCourses(_selectedCourses);
  };

  const handleChangeSpecialDiscount = (courseId, value) => {
    let _selectedCourses = cloneDeep(selectedCourses);
    const courseIndex = findIndex(_selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    _selectedCourses[courseIndex].specialDiscount = parseFloat(value);
    setSelectedCourses(_selectedCourses);
  };

  const handlePrintedBookChange = (courseId) => {
    let _selectedCourses = cloneDeep(selectedCourses);
    const courseIndex = findIndex(_selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    const printedBooksIncluded = getPrintedBooksStatus(courseId);
    _selectedCourses[courseIndex].printedBooksIncluded = !printedBooksIncluded;
    setSelectedCourses(_selectedCourses);
  };

  const getSpecialDiscountForCourse = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex]?.specialDiscount;
  }

  const getCourseAmount = (course) => {
    const courseFee = course.fee;
    const _discount = getDiscount(course);
    const _specialDiscount = getSpecialDiscountForCourse(course._id) || 0;
    return courseFee - _discount - _specialDiscount;
  };

  const isCourseSelected = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return courseIndex !== -1;
  }

  const isCourseEnrolled = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex]?.isEnrolled;
  }

  const getCourseBatches = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex].batches;
  }

  const getSelectedBatch = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex].selectedBatch;
  }

  const getPrintedBooksStatus = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex].printedBooksIncluded;
  }

  const getBatchStartMonth = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    const selectedCourse = selectedCourses[courseIndex];
    const _batches = selectedCourse.batches;
    const batchIndex = findIndex(_batches, batch => batch._id.toString() === selectedCourse?.selectedBatch?.toString());
    if (batchIndex !== -1) {
      return _batches[batchIndex]?.startMonth;
    }
    return null;
  };

  const getBatchEndMonth = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    const selectedCourse = selectedCourses[courseIndex];
    const _batches = selectedCourse.batches;
    const batchIndex = findIndex(_batches, batch => batch._id.toString() === selectedCourse?.selectedBatch?.toString());
    return _batches[batchIndex]?.endMonth;
  };

  const isDisabled = () => {
    return updating || !student || totalAmount < 0 ||
      monthlyScholarship > totalCourseFee ||
      selectedCourses.every(sc => sc.isEnrolled) ||
      ((specialDiscount > 0 || discountOnAdmissionFee > 0) && isEmpty(reference)) ||
      selectedCourses.some(sc => !sc.isEnrolled && includes(['offline', 'live'], sc.course.courseType) && !sc.selectedBatch) ||
      selectedCourses.some(sc => {
        const discount = getDiscount(sc.course);
        const specialDiscount = parseFloat(sc.specialDiscount);
        const discountedFee = sc.course.fee - discount;
        return specialDiscount < 0 || specialDiscount > parseFloat(discountedFee);
      }) ||
      (program.paymentCircle === 'month' && isEmpty(startMonth));
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Student admission"
    >
      {showConfirmModal &&
        <ConfirmModal
          title="Confirm admission"
          description="Are you sure to confirm admission for this course? Please double check before performing this action."
          actionName="Confirm"
          onConfirm={handleEnrollCourses}
          onCancel={() => setShowConfirmModal(false)}
        />}
      <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>
          {!includes(['owner', 'admin'], currentUser?.role) && !currentBranch?.permissions?.canAdmitCentralStudents &&
            <div className="flex items-center space-x-1 text-yellow-600 mb-4">
              <ExclamationTriangleIcon className="h-5 w-5" />
              <p>You are not allowed to take admissions for other branches.</p>
            </div>}

          <div>
            <h2 className="text-lg font-medium text-gray-900">Student information</h2>
            <dl className="mt-6 space-y-4 border-t border-gray-200 text-sm leading-6">
              <div className="mt-4 sm:flex">
                {/* <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Registration number</dt> */}
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="flex w-full space-x-4">
                    <Input placeholder="Registration no." value={registrationNo} onChange={(event) => setRegistrationNo(event.target.value)} />
                    <Button label="Search" onClick={searchStudent} />
                  </div>
                </dd>
              </div>

              {student &&
                <>
                  <div className="sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Stundets's name</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-600">{student?.name}</div>
                    </dd>
                  </div>
                  <div className="sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Student's mobile</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-600">{student?.mobile}</div>
                    </dd>
                  </div>
                </>}
            </dl>
          </div>

          {student &&
            <div>
              <dl className="mt-6 space-y-4 border-gray-200 text-sm leading-6">
                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Program</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="w-full">
                      <Select value={program?._id} options={programs} onChange={(event) => setProgram(programs[event.target.selectedIndex - 1])} />
                    </div>
                  </dd>
                </div>

                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Branch</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="w-full">
                      <Select value={branch?._id} options={branches} onChange={(event) => setBranch(branches[event.target.selectedIndex - 1])} />
                    </div>
                  </dd>
                </div>

                {program && branch &&
                  <div>
                    <fieldset className="border-b border-t border-gray-200">
                      <div className="divide-y divide-gray-200">
                        {courses?.map((course) => (
                          <div key={course._id} className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                              <label className="font-medium text-gray-900">
                                {course.name}
                              </label>
                              <p className="text-gray-500">
                                <span>Fee: {course.isFree ? 'Free' : `${course.fee}/${program.paymentCircle}`}</span>
                                {program.paymentCircle === 'onetime' &&
                                  <>
                                    <span> &bull; </span>
                                    <span>Discount: {getDiscount(course)}</span>
                                  </>}
                                <span> &bull; </span>
                                {courseType === 'offline' ?
                                  <span>Payment mode: {program.paymentCircle === 'month' ? 'monthly' : 'onetime'}</span>
                                  : <span>Course type: {course.courseType === 'exam' ? 'Online exam' : capitalize(course.courseType)}</span>}
                              </p>

                              {isCourseSelected(course._id) && !isCourseEnrolled(course._id) &&
                                <>
                                  <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-x-2">
                                    {includes(['offline', 'live'], course.courseType) &&
                                      <Select
                                        col={1}
                                        label="Select batch"
                                        value={getSelectedBatch(course._id)}
                                        options={getCourseBatches(course._id)}
                                        onChange={(event) => handleSelectBatch(course._id, event.target.value)}
                                      />}

                                    {program.paymentCircle === 'onetime' &&
                                      <Input
                                        col={1}
                                        type="number"
                                        min="0"
                                        label="Special discount"
                                        value={getSpecialDiscountForCourse(course._id) || ''}
                                        onChange={(event) => handleChangeSpecialDiscount(course._id, event.target.value)}
                                      />}

                                    {program.paymentCircle === 'onetime' &&
                                      <Input col={1} disabled label="Amount" value={getCourseAmount(course)} />}

                                    {program.paymentCircle === 'month' &&
                                      <Input col={1} disabled type="month" label="Start month" value={getBatchStartMonth(course._id)} />}

                                    {program.paymentCircle === 'month' &&
                                      <Input col={1} disabled type="month" label="End month" value={getBatchEndMonth(course._id)} />}
                                  </div>
                                  {course.printedBooksIncluded &&
                                    <div className="flex mt-2">
                                      <InlineCheckbox
                                        label="Printed books included"
                                        checked={getPrintedBooksStatus(course._id)}
                                        onChange={() => handlePrintedBookChange(course._id)}
                                      />
                                    </div>}
                                </>
                              }
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                              <input
                                type="checkbox"
                                disabled={isCourseEnrolled(course._id)}
                                checked={isCourseSelected(course._id)}
                                onChange={() => handleSelectCourse(course)}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>}
              </dl>
            </div>}
        </div>

        {selectedCourses?.length > 0 &&
          <div className="mt-10 lg:mt-0">
            <h2 className="text-lg font-medium text-gray-900">Payment summary</h2>
            <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
              <div className="flex flex-col px-4 py-6 sm:px-6">
                <p className="font-medium text-gray-700 hover:text-gray-800">
                  Selected courses:
                </p>
                <div className="ml-4 text-sm">
                  <ul className="">
                    {selectedCourses.map((selectedCourse, index) => (
                      <li
                        key={selectedCourse.course._id}
                        className=""
                      >
                        {index + 1}. {selectedCourse.course.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <dl className="space-y-4 border-t border-gray-200 px-4 py-6 sm:px-6">
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Course fee</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{totalCourseFee.toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Promotional discount</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{discount?.toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-semibold">Sub-total</dt>
                  <dd className="text-sm font-semibold text-gray-900">৳{(totalCourseFee - discount).toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">{program.paymentCircle === 'onetime' ? `Special discount(s)` : `Monthly scholarship`}</dt>
                  {program.paymentCircle === 'onetime' ?
                    <dd className="text-sm font-medium text-gray-900">৳{specialDiscount?.toLocaleString()}</dd>
                    :
                    <dd className="text-sm font-medium text-gray-900">
                      <Input type="number" textRight value={monthlyScholarship} onChange={(event) => setMonthlyScholarship(event.target.value)} />
                    </dd>}
                </div>

                {program.paymentCircle === 'month' &&
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">Billing start month</dt>
                    <dd className="text-sm font-medium text-gray-900">
                      <Input
                        col={1}
                        type="month"
                        // disabled={programEnrolled && existingEnrolledCount > 0}
                        // min={format(startOfMonth(startOfToday()), 'yyyy-MM')}
                        value={startMonth || ''}
                        onChange={(event) => setStartMonth(event.target.value)}
                      />
                    </dd>
                  </div>}

                {program.paymentCircle === 'onetime' &&
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">
                      <InlineCheckbox label="Include VAT (15%)" checked={includeVat} onChange={() => setIncludeVat(!cloneDeep(includeVat))} />
                    </dt>
                    <dd className="text-sm font-semibold text-gray-900">৳{vat.toLocaleString()}</dd>
                  </div>}
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base font-medium">Total {program.paymentCircle === 'month' ? '(monthly fee)' : ''}</dt>
                  <dd className="text-base font-medium text-gray-900">৳{totalAmount.toLocaleString()}</dd>
                </div>

                {!programEnrolled && program.admissionFeeEnabled &&
                  <>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                      <dt className="text-base font-medium">Admission fee</dt>
                      <dd className="text-base font-medium text-gray-900">৳{program.admissionFee.toLocaleString()}</dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-sm">Discount on admission fee</dt>
                      <dd className="text-sm font-semibold text-gray-900">
                        <Input type="number" textRight value={discountOnAdmissionFee} onChange={(event) => setDiscountOnAdmissionFee(event.target.value)} />
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-sm">Admission fee payment</dt>
                      <dd className="text-sm font-semibold text-gray-900">{(program.admissionFee - parseFloat(discountOnAdmissionFee)).toLocaleString()}</dd>
                    </div>
                  </>}

                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-sm">Reference</dt>
                  <dd className="text-sm font-medium text-gray-900 w-2/3">
                    <Input value={reference || ''} onChange={(event) => setReference(event.target.value)} />
                  </dd>
                </div>
              </dl>
              {/* } */}

              <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                <SubmitButton
                  disabled={isDisabled()}
                  className="w-full"
                  label="Confirm admission"
                  onClick={() => setShowConfirmModal(true)}
                />
              </div>
            </div>
          </div>}
      </div>
    </StackedLayout>
  )
};
