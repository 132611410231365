import { useEffect, useRef, useState } from 'react';
import { cloneDeep, forEach } from 'lodash';
import moment from 'moment';
import toast from 'react-hot-toast';


import SmsPageLayout from './layouts/SmsPageLayout';
import { TextArea, Select, Input, InlineCheckbox } from '../../components/forms/fields';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { getSmsCounts } from '../../utils';
import { SubmitButton, Button } from '../../components/buttons';
import { sendNumberSms } from '../../services/smsEvent';
import { getBranchSmsMaskingList } from '../../services/instituteSms';
import SmsPreviewModal from './components/SmsPreviewPanel';


export default function NumberSmsPage() {
  const smsBodyRef = useRef();
  const [smsBody, setSmsBody] = useState('');
  const [smsType, setSmsType] = useState('Text');
  const [smsCount, setSmsCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [contact, setContact] = useState('');
  const [contactList, setContactList] = useState([]);
  const [validContactList, setValidContactList] = useState([]);
  const [errorContactCount, setErrorContactCount] = useState(0);
  const [scheduledAt, setScheduledAt] = useState(null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [smsMasking, setSmsMasking] = useState(null);
  const [maskOptions, setMaskOptions] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _maskingList = await getBranchSmsMaskingList();
        let _maskingOptions = [];
        forEach(_maskingList, masking => {
          _maskingOptions.push({ name: masking.senderId, value: masking._id });
        });
        setMaskOptions(_maskingOptions);
      } catch (error) {
        toast.error(error.message)
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    if (!isScheduled) {
      setScheduledAt(null);
    }
  }, [isScheduled]);

  useEffect(() => {
    const _contactList = contact.split(",");
    setContactList(_contactList);
    setErrorContactCount([]);
    checkValidNumber(_contactList);
  }, [contact])

  const handleSmsBodyChange = (event) => {
    let smsBody = '';
    let smsCounts = '';
    const position = smsBodyRef.selectionStart;
    if (typeof event !== 'object') {
      let temp = this.state.smsBody;
      let start = temp.substring(0, position);
      let end = temp.substring(parseInt(position), temp.length);
      smsBody = start.concat(event);
      smsBody = smsBody.concat(end);
    } else {
      smsBody = event.target.value;
      smsCounts = getSmsCounts(smsBody);
    }
    setSmsBody(smsBody);
    setCharacterCount(smsCounts.characterCount);
    setSmsCount(smsCounts.smsCount);
    setSmsType(smsCounts.smsType);
  };

  const checkValidNumber = (_contactList) => {
    _contactList = _contactList || contactList;
    const pattern = /(^(\+880|880|0)?(1){1}[3456789]{1}(\d){8})$/;
    let errorList = [];
    let correctList = []

    for (let i in _contactList) {
      if (pattern.test(_contactList[i])) {
        correctList = [...correctList, _contactList[i]];
      } else {
        errorList = [...errorList, _contactList[i]];
      }
    }
    setValidContactList(correctList);
    setErrorContactCount(errorList.length);
  };

  const sendSMS = async (event) => {
    try {
      event.preventDefault();
      setIsProcessing(true);

      if (isScheduled && !scheduledAt && moment(scheduledAt) <= moment()) {
        toast.error('Scheduled time should be in future');
        return
      }

      let payload = {
        scheduledAt: isScheduled ? moment(scheduledAt).zone('+06:00').format() : null,
        smsMasking: smsMasking,
        smsBody: smsBody,
        contacts: validContactList

      };
      await sendNumberSms(payload);
      toast.success('Your request is being processed.');
      setSmsBody('')
      setIsScheduled(false);
      setScheduledAt(null);
      setContact('');
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const isDisabled = () => {
    return isProcessing || smsCount <= 0 ||
      validContactList.length <= 0 ||
      (isScheduled && moment(scheduledAt) <= moment());
  };

  return (
    <SmsPageLayout
      currentSidebarNavigation="Numner SMS"
      isProcessing={isProcessing}
    >
      {showConfirmModal &&
        <ConfirmModal
          title={isScheduled ? 'Schedule SMS' : 'Send SMS'}
          description={`Are you sure to ${isScheduled ? 'Sschedule' : 'send'} sms? Please double check before performing this action.`}
          actionName={isScheduled ? 'Schedule SMS' : 'Send SMS'}
          onConfirm={sendSMS}
          onCancel={() => setShowConfirmModal(false)}
        />}

      {showPreviewModal &&
        <SmsPreviewModal
          smsBody={smsBody}
          contacts={validContactList}
          onCancel={() => setShowPreviewModal(false)}
        />}

      {!showPreviewModal &&
        <>
          <div className="grid grid-cols-2 gap-x-8">
            <div>
              <TextArea
                ref={smsBodyRef}
                label="SMS body"
                value={smsBody}
                maxLength={smsType === "Unicode" ? 335 : 765}
                rows={6}
                onChange={handleSmsBodyChange}
              />
              <p className="mt-2 text-xs">Characters: {characterCount} | SMS count : {smsCount} | Type : {smsType}</p>
            </div>
            <div>
              <TextArea
                rows={6}
                label="Mobile numbers (comma separated)"
                value={contact}
                onChange={(event) => setContact(event.target.value)} />
              <p className="mt-2 text-xs">Contacts: {contactList.length} | Valid: {validContactList.length} |{' '}
                <span className="text-red-600">Invalid: {errorContactCount}</span></p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-x-8">
            <div>
              <Select
                label="Mask options"
                emptyStateLabel="No Masking"
                options={maskOptions}
                value={smsMasking}
                onChange={(event) => setSmsMasking(event.target.value)}
              />
            </div>
            <div>
              <div className="mb-1.5">
                <InlineCheckbox
                  label="Schedule Date Time"
                  checked={isScheduled}
                  onChange={() => setIsScheduled(!cloneDeep(isScheduled))}
                />
              </div>
              <Input
                type="datetime-local"
                disabled={!isScheduled}
                value={scheduledAt || ''}
                min={moment().format('YYYY-MM-DDThh:mm')}
                onChange={(event) => setScheduledAt(event.target.value)} />
            </div>
            <div></div>
          </div>
          <div className="space-x-4">
            <SubmitButton
              label={isScheduled ? 'Schedule SMS' : 'Send SMS'}
              disabled={isDisabled()}
              onClick={() => setShowConfirmModal(true)} />

            <Button
              label="Preview"
              disabled={validContactList?.length <= 0 || isProcessing}
              onClick={() => setShowPreviewModal(true)} />

          </div>
        </>}
    </SmsPageLayout>
  )
};
