import { useState, useEffect } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { omit, isEmpty, round } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import { Button } from '../../components/buttons';
import { Input } from '../../components/forms/fields';
import { getSmsEvents } from '../../services/smsEvent';
import { getNormalizedDateTime } from '../../utils';


const sidebarNavigation = [
  { name: 'Numner SMS', href: '/sms/number' },
  { name: 'Excel SMS', href: '/sms/excel' },
  { name: 'Group SMS', href: '/sms/group' },
  { name: 'SMS reports', href: '/sms/reports' },
];
const headers = ['Time', 'Sms Body', 'Success', 'Cost', 'Status'];

export default function BranchSmsReportsPage() {
  const defaultFromDate = getNormalizedDateTime(new Date(), 'YYYY-MM-DD');
  const defaultToDate = getNormalizedDateTime(new Date(), 'YYYY-MM-DD');
  const [smsEvents, setSmsEvents] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [queryString, setQueryString] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    currentPage,
    selectedBranch,
    fromDate,
    toDate,
    queryString
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 20,
        page: currentPage,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        status: ['pending', 'completed', 'failed']
      }

      if (fromDate) {
        params.fromDate = moment(fromDate).startOf('day').zone('+06:00').format();
      }
      if (toDate) {
        params.toDate = moment(toDate).endOf('day').zone('+06:00').format();
      }
      const data = await getSmsEvents(params);
      setSmsEvents(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString : null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="SMS reports"
    >
      <SectionHeader
        title="SMS history"
        subTitle="See all SMS log for your institution."
      >
      </SectionHeader>

      <div className="mx-2 grid max-w-7xl grid-cols-3 gap-x-4 text-sm md:gap-x-6">
        <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
          <fieldset>
            <Input type="date" label="From date" value={fromDate || ''} onChange={(event) => setFromDate(event.target.value)} />
          </fieldset>
          <fieldset>
            <Input type="date" label="To date" value={toDate || ''} onChange={(event) => setToDate(event.target.value)} />
          </fieldset>
        </div>
        <div>
          <div className="mt-8">
            <Button
              label="Clear filters"
              onClick={() => {
                setSelectedBranch(null);
                setFromDate(defaultFromDate);
                setToDate(defaultToDate);
              }}
            />
          </div>
        </div>
      </div>

      <Table
        headers={headers}
        itemsCount={smsEvents?.length}
        pagingData={pagingData}
        onPageChange={onPageChange}
      >
        {smsEvents?.map((smsEvent, index) => (
          <tr key={`sms-index[${index}]`} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {getNormalizedDateTime(smsEvent.createdAt)}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{smsEvent.smsBody}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{smsEvent.successCount}/{smsEvent.totalContacts}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{round(smsEvent.actualCost, 2)}</td>
            <td
              className={`pr-3 py-4 text-sm ${smsEvent.status === 'success' ? 'text-green-600' : smsEvent.status === 'failed' ? 'text-red-600' : 'text-gray-500'}`}>
              {smsEvent.status}
            </td>
          </tr>
        ))}

      </Table>
    </StackedLayout>
  )
};