import { useEffect, useState } from 'react';
import { round } from 'lodash';
import toast from 'react-hot-toast';

import StackedLayout from '../../../layouts/StackedLayout';
import { SubmitButton, Button } from '../../../components/buttons';
import { getBranchSmsBalance } from '../../../services/sms';
import { EnvelopeIcon } from '@heroicons/react/24/solid';


const sidebarNavigation = [
  { name: 'Numner SMS', href: '/sms/number' },
  { name: 'Excel SMS', href: '/sms/excel' },
  { name: 'Group SMS', href: '/sms/group' },
  { name: 'SMS reports', href: '/sms/reports' },
];

export default function SmsPageLayout(props) {
  const { currentSidebarNavigation, isProcessing } = props;
  const [smsBalance, setSmsBalance] = useState(0);


  useEffect(() => {
    async function _fetchData() {
      try {
        const _smsBalance = await getBranchSmsBalance();
        setSmsBalance(_smsBalance?.smsBalance || 0);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchData();
  }, [isProcessing]);

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={currentSidebarNavigation}
    >
      <div>
        <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0 mb-10">
          <div className="flex items-center gap-x-6">
            <div className="flex mx-auto h-16 w-16 rounded-full items-center justify-center ring-1 ring-green-100">
              <EnvelopeIcon className="h-10 w-10 text-green-600" />
            </div>
            <h1>
              <div className="text-sm leading-6 text-gray-500">
                SMS balance
              </div>
              <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
                BDT {round(smsBalance, 2)}
              </div>
            </h1>
          </div>
          <div className="flex items-center gap-x-4 sm:gap-x-6">
            <SubmitButton
              label="Add balance"
              onClick={() => {
                window.location.href = `/settings/sms/branch/recharge`;
              }}
            />
          </div>
        </div>
      </div>
      {props.children}
    </StackedLayout>
  )
};
