import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import toast from 'react-hot-toast';
import { includes, omit, isEmpty } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import DownloadTransactionModal from '../../components/modals/DownloadTransactionModal';
import { Button } from '../../components/buttons';
import { Input } from '../../components/forms/fields';
import { getBranches } from '../../services/branch';
import { getSmsLog } from '../../services/sms';
import { getNormalizedDateTime } from '../../utils';


const sidebarNavigation = [
  { name: 'Dashboard', href: '/reports/dashboard' },
  { name: 'Transactions', href: '/reports/transactions' },
  { name: 'SMS history', href: '/reports/sms-history' },
];
const headers = ['Time', 'Mobile', 'Body', 'Masking', 'Branch', 'Status', 'Cost'];

export default function SmsHistoryPage() {
  const defaultFromDate = getNormalizedDateTime(new Date(), 'YYYY-MM-DD');
  const defaultToDate = getNormalizedDateTime(new Date(), 'YYYY-MM-DD');
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [smsLogs, setSmsLogs] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [queryString, setQueryString] = useState('');
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    currentPage,
    selectedBranch,
    fromDate,
    toDate,
    queryString
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 20,
        page: currentPage,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: queryString
      }

      if (selectedBranch) {
        params.branch = selectedBranch;
      }
      if (fromDate) {
        params.fromDate = moment(fromDate).startOf('day').zone('+06:00').format();
      }
      if (toDate) {
        params.toDate = moment(toDate).endOf('day').zone('+06:00').format();
      }
      const data = await getSmsLog(params);
      setSmsLogs(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString : null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="SMS history"
    >
      {showDownloadModal &&
        <DownloadTransactionModal
          currentUser={currentUser}
          onCancel={() => setShowDownloadModal(false)}
        />}
      <SectionHeader
        title="SMS history"
        subTitle="See all SMS log for your institution."
      >
      </SectionHeader>

      <div className="mx-2 grid max-w-7xl grid-cols-3 gap-x-4 text-sm md:gap-x-6">
        {/* {includes(['owner', 'admin'], currentUser.role) &&
        <div className="grid auto-rows-min grid-cols-1 gap-y-10">
          <fieldset>
            <Select label="Student branch" value={selectedBranch} options={branches} onChange={(event) => setSelectedBranch(event.target.value)} />
          </fieldset>
        </div>} */}
        <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
          <fieldset>
            <Input type="date" label="From date" value={fromDate || ''} onChange={(event) => setFromDate(event.target.value)} />
          </fieldset>
          <fieldset>
            <Input type="date" label="To date" value={toDate || ''} onChange={(event) => setToDate(event.target.value)} />
          </fieldset>
        </div>
        <div>
          <div className="mt-8">
            <Button
              label="Clear filters"
              onClick={() => {
                setSelectedBranch(null);
                setFromDate(defaultFromDate);
                setToDate(defaultToDate);
              }}
            />
          </div>
        </div>
      </div>

      <Table
        headers={headers}
        itemsCount={smsLogs?.length}
        pagingData={pagingData}
        onPageChange={onPageChange}
        queryString={queryString}
        onSearch={onSearch}
        searchPlaceholder="Search by mobile number"
      >
        {smsLogs?.map((sms, index) => (
          <tr key={`sms-index[${index}]`} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {getNormalizedDateTime(sms.createdAt)}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{sms.mobile}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{sms.smsBody}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{sms.senderId || 'NOMASK'}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{sms?.branch?.name || 'System'}</td>
            <td
              className={`pr-3 py-4 text-sm ${sms.status === 'success' ? 'text-green-600' : sms.status === 'failed' ? 'text-red-600' : 'text-gray-500'}`}>
              {sms.status}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{sms.fee || '-'}</td>
          </tr>
        ))}

      </Table>
    </StackedLayout>
  )
};