import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import { Input } from '../../components/forms/fields';
import { SubmitButton, Button } from '../../components/buttons';
import {
  getBkashCredentials,
  createBkashCredentials,
  updateBkashCredentials
} from '../../services/institute';


const sidebarNavigation = [
  { name: 'General information', href: '/settings/institute-info' },
  { name: 'Bkash credentials', href: '/settings/bkash-credentials' },
  { name: 'SMS configuration', href: '/settings/sms/system' },
  { name: 'SMS maskings', href: '/settings/sms/maskings' },
  { name: 'SMS templates', href: '/settings/sms/templates' },
  { name: 'Branch SMS balance', href: '/settings/sms/branch/list' },
  { name: 'Analytics configuration', href: '/settings/analytics' },
];

const defaultUpdateStates = {
  appKey: false,
  appSecret: false,
  username: false,
  password: false
};

export default function BkashCredentialsPage() {
  const [bkashCredentails, setBkashCredentials] = useState(null);
  const [appKey, setAppKey] = useState('');
  const [appSecret, setAppSecret] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [updateStates, setUpdateState] = useState(defaultUpdateStates);

  useEffect(() => {
    async function fetchData() {
      try {
        const _bkashCredentails = await getBkashCredentials();
        setBkashCredentials(_bkashCredentails);
        if (_bkashCredentails) {
          setBkashCredentials(_bkashCredentails);
          setAppKey(_bkashCredentails.appKey);
          setAppSecret(_bkashCredentails.appSecret);
          setUsername(_bkashCredentails.username);
          setPassword(_bkashCredentails.password);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, []);

  const onEdit = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = true;
    setUpdateState(_updateState);
  };

  const onCancel = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = false;
    setUpdateState(_updateState);
    if (fieldName === 'appKey') { setAppKey(bkashCredentails.appKey); }
    else if (fieldName === 'appSecret') { setAppSecret(bkashCredentails.appSecret); }
    else if (fieldName === 'username') { setUsername(bkashCredentails.username); }
    else if (fieldName === 'password') { setPassword(bkashCredentails.password); }
  };

  const onSave = async (event, fieldName, value) => {
    try {
      event.preventDefault();
      let payload = {};
      payload[fieldName] = value;

      let updatedCredentials;
      if (bkashCredentails?._id) {
        updatedCredentials = await updateBkashCredentials(bkashCredentails?._id, payload);
      } else {
        updatedCredentials = await createBkashCredentials(payload);
      }
      setBkashCredentials(updatedCredentials);

      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Bkash credentials"
    >
      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Bkash credentials</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Set bkash credentials for your institution
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">App key</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.appKey && <div className="text-gray-900">{bkashCredentails?.appKey}</div>}
              {updateStates.appKey &&
                <div className="w-full">
                  <Input value={appKey} onChange={(event) => setAppKey(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => onSave(event, 'appKey', appKey)} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'appKey')} />
                  </div>
                </div>}

              {!updateStates.appKey &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'appKey')}
                >
                  Update
                </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">App secret</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.appSecret && <div className="text-gray-900">{bkashCredentails?.appSecret}</div>}
              {updateStates.appSecret &&
                <div className="w-full">
                  <Input value={appSecret} onChange={(event) => setAppSecret(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => onSave(event, 'appSecret', appSecret)} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'appSecret')} />
                  </div>
                </div>}

              {!updateStates.appSecret &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'appSecret')}
                >
                  Update
                </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Username</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.username && <div className="text-gray-900">{bkashCredentails?.username}</div>}
              {updateStates.username &&
                <div className="w-full">
                  <Input value={username} onChange={(event) => setUsername(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => onSave(event, 'username', username)} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'username')} />
                  </div>
                </div>}

              {!updateStates.username &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'username')}
                >
                  Update
                </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Password</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.password && <div className="text-gray-900">{bkashCredentails?.password}</div>}
              {updateStates.password &&
                <div className="w-full">
                  <Input value={password} onChange={(event) => setPassword(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => onSave(event, 'password', password)} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'password')} />
                  </div>
                </div>}

              {!updateStates.password &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'password')}
                >
                  Update
                </button>}
            </dd>
          </div>
        </dl>
      </div>
    </StackedLayout>
  )
};
