import http from '../helpers/http';


export const getSmsLog = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/sms/log`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getSystemSmsBalance = async () => {
  try {
    const { data } = await http.get(`/api/sms/balance/system`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBranchSmsBalance = async () => {
  try {
    const { data } = await http.get(`/api/sms/balance/branch`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBranchSmsBalanceList = async () => {
  try {
    const { data } = await http.get(`/api/sms/balance/list`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
