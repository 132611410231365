import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep, forEach } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input, TextArea } from '../../components/forms/fields';
import { PlusButton } from '../../components/buttons';
import SubjectForm from './components/ExamSubjectForm';
import { getExam, createExam, updateExam } from '../../services/exam';


let defaultSubjects = [
  { name: '', noOfQuestions: 0 }
];

export default function MCQExamEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const examId = params.id;
  const folderId = location?.state?.folderId;
  const [exam, setExam] = useState(null);
  const [name, setName] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(1);
  const [totalMarks, setTotalMarks] = useState(null);
  const [marksPerQuestion, setMarksPerQuestion] = useState(1);
  const [negativeMarksPerQuestion, setNegativeMarksPerQuestion] = useState(0);
  const [durationValue, setDurationValue] = useState(1);
  const [noOfSubjectsToAnswer, setSubjectsToAnswer] = useState(1);
  const [subjects, setSubjects] = useState(defaultSubjects);
  const [syllabus, setSyllabus] = useState('');
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (examId) {
          const _exam = await getExam(examId);
          setExam(_exam);
          setName(_exam.name);
          setTotalQuestions(_exam.totalQuestions);
          setTotalMarks(_exam.totalMarks);
          setMarksPerQuestion(_exam.marksPerQuestion);
          setNegativeMarksPerQuestion(_exam.negativeMarksPerQuestion);
          setDurationValue(_exam?.duration?.value);
          setSubjectsToAnswer(_exam.noOfSubjectsToAnswer);
          setSubjects(_exam.subjects);
          setSyllabus(_exam.syllabus);
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const addSubject = (event) => {
    event.preventDefault();
    const _subjects = cloneDeep(subjects);
    const newItem = { name: '', noOfQuestions: 0, isMandatory: false };
    _subjects.push(newItem);
    setSubjects(() => _subjects);
  }

  const deleteSubject = (event, index) => {
    event.preventDefault();
    const _subjects = cloneDeep(subjects);
    _subjects.splice(index, 1);
    setSubjects(() => _subjects);
  }

  const handleSubjectFieldChange = (event, index) => {
    event.preventDefault();
    const _subjects = cloneDeep(subjects);
    const fieldName = event.target.name === 'subject' ? 'name' : event.target.name;
    _subjects[index][fieldName] = event.target.value;
    setSubjects(_subjects);

  }

  const handleSubjectToggleFieldChange = (fieldName, index, value) => {
    const _subjects = cloneDeep(subjects);
    _subjects[index][fieldName] = value;
    setSubjects(_subjects);

  }

  const saveExam = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let questionCount = 0;
      forEach(subjects, subject => {
        questionCount = questionCount + parseInt(subject.noOfQuestions);
      });

      if (totalQuestions > questionCount) {
        throw new Error('Invalid question count')
      }

      let payload = {
        name,
        totalMarks,
        marksPerQuestion,
        negativeMarksPerQuestion,
        totalQuestions,
        noOfSubjectsToAnswer,
        subjects,
        syllabus,
        duration: {
          value: durationValue,
          type: 'minutes'
        }
      };

      if (folderId) {
        payload.folder = folderId;
      }

      if (examId) {
        await updateExam(examId, payload);
        toast.success(`Exam updated successfully.`);
      } else {
        await createExam(payload);
        toast.success(`Exam created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  }

  const isDisabled = () => {
    let mandatorySubjectCount = 0;
    forEach(subjects, s => {
      if (s.isMandatory) { mandatorySubjectCount = mandatorySubjectCount + 1; }
    })
    return updating || !name || !totalMarks || !marksPerQuestion || totalQuestions < 1 ||
      durationValue < 1 || noOfSubjectsToAnswer < 1 || subjects.length < noOfSubjectsToAnswer ||
      mandatorySubjectCount > noOfSubjectsToAnswer;
  }

  const goBack = () => {
    if (folderId || exam?.folder) {
      navigate(`/contents/exams/mcq/folder/${folderId || exam?.folder}`);
    } else {
      navigate(`/contents/exams/mcq`);
    }
  };

  return (
    <StackedLayout
      loading={loading}
    >
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {exam?.name || 'Create new exam'}
          </h1>
          {exam &&
            <p className="mt-2 text-base text-gray-500">
              Edit information for this exam.
            </p>}
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={exam ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveExam}
        onCancel={goBack}
      >
        <FieldSet
          title="Exam info"
          subTitle="Give a exam name, chosse types and exam type."
        >
          <Input col={3} label="Name" name="name" value={name} onChange={(event) => setName(event.target.value)} />
          <Input type="number" col={2} label="Questions to answer" name="totalQuestions" value={totalQuestions} onChange={(event) => setTotalQuestions(event.target.value)} />
          <Input type="number" col={2} label="Total marks" name="totalMarks" value={totalMarks} onChange={(event) => setTotalMarks(event.target.value)} />
          <Input type="number" col={2} label="Marks per question" name="marksPerQuestion" value={marksPerQuestion} onChange={(event) => setMarksPerQuestion(event.target.value)} />
          <Input type="number" col={2} label="Negative marks per question" name="negativeMarksPerQuestion" value={negativeMarksPerQuestion} onChange={(event) => setNegativeMarksPerQuestion(event.target.value)} />
          <Input type="number" col={2} label="Subjects to answer" name="noOfSubjectsToAnswer" value={noOfSubjectsToAnswer} onChange={(event) => setSubjectsToAnswer(event.target.value)} />

          <Input type="number" col={2} label="Duration (minutes)" name="durationValue" value={durationValue} onChange={(event) => setDurationValue(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="Subject set up"
          subTitle="Set up questions per subject to answer. "
        >
          {subjects?.length > 0 && subjects.map((subject, index) => (
            <SubjectForm
              key={`subject-settings-index[${index}]`}
              subjectCount={subjects.length}
              subject={subject}
              index={index}
              onDelete={deleteSubject}
              onFieldChange={handleSubjectFieldChange}
              onToggleFieldChange={handleSubjectToggleFieldChange}
            />
          ))}
          <div className={`${subjects?.length === 1 ? 'mt-9' : ''} col-span-2`}>
            <PlusButton label="Add subject" onClick={addSubject} />
          </div>
        </FieldSet>

        <FieldSet
          title="Exam syllabus"
          subTitle="Set exam syllabus."
        >
          <TextArea label="Syllabus" name="syllabus" value={syllabus} onChange={(event) => setSyllabus(event.target.value)} />
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};
