import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import { getBranchSmsBalanceList } from '../../services/sms';
import Table from '../../components/tables/StackedTable';
import { getNormalizedDateTime } from '../../utils';
import { round } from 'lodash';


const sidebarNavigation = [
  { name: 'General information', href: '/settings/institute-info' },
  { name: 'Bkash credentials', href: '/settings/bkash-credentials' },
  { name: 'System SMS configuration', href: '/settings/sms/system' },
  { name: 'Branch SMS balance', href: '/settings/sms/branch/list' },
  { name: 'Analytics configuration', href: '/settings/analytics' },
];

export default function BranchSmsBalanceListPage() {
  const [smsBalanceList, setSmsBalaceList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const _smsBalanceList = await getBranchSmsBalanceList();
        setSmsBalaceList(_smsBalanceList);
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Branch SMS balance"
    >

      <div>
        <Table
          title="Branch SMS balance"
          subTitle={`See current balance for your branches.`}
          headers={['Branch', 'Balance', 'Last recharged at']}
          items={smsBalanceList}
          itemsCount={smsBalanceList.length}
        >
          {smsBalanceList?.map((item) => (
            <tr key={item._id} className="text-center">
              <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                {item.branch.name}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">{round(item.smsBalance, 2)}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{getNormalizedDateTime(item.updatedAt)}</td>
            </tr>
          ))}
        </Table>
      </div>
    </StackedLayout>
  )
};
