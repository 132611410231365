import http from '../helpers/http';


export const getInstituteSmsBalance = async () => {
  try {
    const { data } = await http.get(`/api/institutesms/balance`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getInstituteSms = async () => {
  try {
    const { data } = await http.get(`/api/institutesms/institute`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getInstituteSmsMaskingList = async () => {
  try {
    const { data } = await http.get(`/api/institutesms/masking/institute`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBranchSmsMaskingList = async () => {
  try {
    const { data } = await http.get(`/api/institutesms/masking/branch`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateInstituteSmsMasking = async (maskingId, paylaod) => {
  try {
    const { data } = await http.put(`/api/institutesms/masking/${maskingId}`, paylaod);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getSmsTemplate = async (eventType) => {
  try {
    const { data } = await http.get(`/api/institutesms/templates/${eventType}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createSmsTemplate = async (paylaod) => {
  try {
    const { data } = await http.post(`/api/institutesms/templates`, paylaod);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateSmsTemplate = async (smsTemplateId, paylaod) => {
  try {
    const { data } = await http.put(`/api/institutesms/templates/${smsTemplateId}`, paylaod);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
